<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left
              slot="left-toolbar"
              @terminatedReload="terminatedReload"
              v-bind:heading="$t('card.terminated-title')"
              v-bind:enableAction="selectedItems.length > 0"
              v-bind:selectedItems="selectedItems"
              v-on:revokeSelected="revokeSelected"
            />
            <header-right
              slot="right-toolbar"
              @terminatedReload="terminatedReload"
              :enableAction="selectedItems.length > 0"
            />
            <p
              v-if="
                (!myTerminatedList || myTerminatedList.length <= 0) &&
                !axiosLoading &&
                !networkError
              "
              class="align-center center-align"
            >
              {{ $t("text.terminated-empty") }}
            </p>

            <v-list
              :data-intro="$t('introjs.this-is-sent-list')"
              two-line
              class="pa-1"
              v-if="!networkError && !axiosLoading && myTerminatedList"
            >
              <template v-for="(item, index) in myTerminatedList">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="$t(`${'label.' + item.header}`)"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item
                  v-else
                  :key="item.id_hash"
                  @click.self="showTerminatedDetail(item.id_hash)"
                  v-ripple="{ class: `primary--text` }"
                >
                  <label class="mr-2 check--blank"></label>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-on="on"
                        v-bind="attrs"
                        class="d-none d-sm-block mr-2"
                        @click="showTerminatedDetail(item.id_hash)"
                      >
                        <v-img :src="item.avatar_path"></v-img>
                      </v-list-item-avatar>
                    </template>
                    <span>{{
                      `${$t("text.terminated-by")} : ${item.avatar_label}`
                    }}</span>
                  </v-tooltip>

                  <v-list-item-content
                    @click="showTerminatedDetail(item.id_hash)"
                  >
                    <v-list-item-title>
                      <span
                        :data-intro="$t('introjs.this-is-sender-name')"
                        class="sub-headline"
                      >
                        {{ item.subtitle }}
                      </span>
                      <div
                        class="d-inline-flex align-self-center align-center align--vertical-center"
                        :data-intro="$t('introjs.badge-is-tag-for-memo-status')"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="parseInt(item.has_attachments) == 1"
                              class="att-icon mx-1"
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ iconAttachment }}
                            </v-icon>
                          </template>
                          <span>{{ $t("tooltip.attachment-exist") }}</span>
                        </v-tooltip>

                        <div v-if="!$vuetify.breakpoint.smAndDown">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="parseInt(item.urgensi) == 1"
                                class="att-icon mx-1"
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ iconUrgent }}
                              </v-icon>
                            </template>
                            <span>{{ $t("tooltip.urgent") }}</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tipe ==='subholding'" href="javascript:void(0)"
                              class="label theme-bg7 text-white f-12">{{ $t("badge.subholding-memo") }}</a>
                          </template>
                          <span>{{ $t("tooltip.subholding-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tipe ==='xnusantara'" href="javascript:void(0)"
                              class="label theme-bg8 text-white f-12">{{ $t("badge.xnusantara-letter") }}</a>
                          </template>
                          <span>{{ $t("tooltip.xnusantara-letter") }}</span>
                        </v-tooltip>

                         <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tipe ==='masuk'" href="javascript:void(0)"
                              class="label theme-bg6 text-white f-12">{{ $t("badge.incoming-letter") }}</a>
                          </template>
                          <span>{{ $t("tooltip.incoming-letter") }}</span>
                        </v-tooltip>

                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tipe === 'eksternal'"
                              href="javascript:void(0)" class="label theme-bg3 text-white f-12">{{
                              $t("badge.external-letter") }}</a>
                          </template>
                          <span>{{ $t("tooltip.external-letter") }}</span>
                        </v-tooltip>
                        
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="isSecret(item.tag)"
                                class="att-icon mx-1"
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ iconSecret }}
                              </v-icon>
                            </template>
                            <span>{{ $t("tooltip.secret") }}</span>
                          </v-tooltip>
                        </div>

                        <a
                          v-if="
                            $vuetify.breakpoint.smAndDown &&
                            isSecret(item.tag)
                          "
                          href="javascript:;"
                          class="label theme-bg3 text-white f-12"
                          >{{ $t("badge.confidential") }}</a
                        >
                        <a
                          v-if="
                            $vuetify.breakpoint.smAndDown &&
                            isUrgent(item.tag)
                          "
                          href="javascript:void(0)"
                          class="label theme-bg3 text-white f-12"
                          >{{ $t("badge.urgent") }}</a
                        >

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe ==='bom'"
                              href="javascript:void(0)"
                              class="label theme-bg4 text-white f-12"
                              >{{ $t("badge.directors-memo") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.directors-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe ==='masuk'"
                              href="javascript:void(0)"
                              class="label theme-bg6 text-white f-12"
                              >{{ $t("badge.incoming-letter") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.incoming-letter") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe ==='izin_prinsip'"
                              href="javascript:void(0)"
                              class="label theme-bg4 text-white f-12"
                              >{{ $t("badge.permit-memo") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.permit-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="parseInt(item.has_delegated) == 1"
                              href="javascript:void(0)"
                              class="label theme-bg text-white f-12"
                              >{{ $t("badge.has-delegated") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.has-delegated") }}</span>
                        </v-tooltip>
                      </div>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      :data-intro="$t('introjs.this-is-memo-number-and-title')"
                      class="d-none d-sm-block"
                    >
                      {{ $t("label.reason") + ": "
                      }}{{ item.comment ? cutJudul(item.comment, 150) : "N/A" }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="d-block d-sm-none">
                      {{ item.alasan ? cutJudul(item.subtitle, 150) : "N/A" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer></v-spacer>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs" class="terminated__icon">
                        <v-icon>{{ iconDeleted }}</v-icon>
                      </div>
                    </template>
                    <span>{{ $t("tooltip.this-draft-has-terminated") }}</span>
                  </v-tooltip>

                  <span
                    :data-intro="$t('introjs.this-is-sending-date')"
                    @click="showTerminatedDetail(item.id_hash)"
                  >
                    {{ $date(item.data_time).format("DD MMM YY") }}<br />{{
                      $date(item.data_time).format("HH:mm")
                    }}
                    WIB
                  </span>
                </v-list-item>
              </template>
            </v-list>
            <v-card-text
              v-if="networkError || axiosLoading || !myTerminatedList"
            >
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
              ></v-skeleton-loader>
            </v-card-text>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>
    <insert-comment
      v-bind:showCommentDialog="showCommentDialog"
      v-bind:submitOptions="submitOptions"
      v-on:showHideCommentDialog="showHideCommentDialog"
      v-on:commentFilled="commentFilled"
    ></insert-comment>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { isIntroActive, rowPerPage } from "@/constants/config";
import dayjs from "dayjs";
import "intro.js/minified/introjs.min.css";
import InsertComment from "@/components/commonComponents/InsertComment.vue";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiSpeedometerSlow,
  mdiSpeedometerMedium,
  mdiSpeedometer,
  mdiCogTransfer,
  mdiBackupRestore,
  mdiTextBoxRemoveOutline,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
    InsertComment,
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconSlow: mdiSpeedometerSlow,
    iconMedium: mdiSpeedometerMedium,
    iconFast: mdiSpeedometer,
    iconDirect: mdiCogTransfer,
    iconRevoke: mdiBackupRestore,
    iconDeleted: mdiTextBoxRemoveOutline,
    selectedItems: [],
    isCheckedAll: false,
    showCommentDialog: false,
    submitOptions: undefined,
    comment: undefined,
  }),
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("terminated", [
      "terminatedList",
      "axiosLoading",
      "filterParams",
      "selectAllTerminated",
    ]),
    currUserNik() {
      if (!this.currentUser.nik) return null;
      return this.currentUser.nik;
    },
    myTerminatedList() {
      if (!this.terminatedList) return [];
      return this.terminatedList.data;
    },
    memoIds() {
      if (!this.terminatedList) return [];
      return this.terminatedList.data.map(({ id_hash }) => id_hash);
    },
  },
  created() {
    this.setFilterParams({
      sortBy: "data_time",
      sortByDesc: "desc",
      perPage: rowPerPage,
      currentPage: 1,
      only: "",
      filter: "",
      from: 0,
      to: 0,
      lastPage: 1,
    });
    this.terminatedReload();
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },
  watch: {
    selectAllTerminated(val) {
      if (this.selectedItems == this.memoIds) return;

      this.setSelectAllTerminated(false);
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      if (val === true) {
        this.selectedItems = this.addToArray(this.selectedItems, this.memoIds);
      }
      this.updateCheckAll();
    },
  },
  methods: {
    ...mapActions("terminated", [
      "loadTerminated",
      "emptyTerminated",
      "setFilterParams",
      "setAxiosLoading",
      "setSelectAllTerminated",
    ]),
    showTerminatedDetail(id) {
      setTimeout(() => {
        //beri jeda untuk animasi ripple
        return this.$router.push({
          name: "terminated.detail",
          params: { id: id },
        });
      }, 300);
    },
    terminatedReload() {
      // this.setFilterParams(this.filterParams);
      this.setSelectAllTerminated(false);
      //remove dulu selected item
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      // this.emptyTerminated();
      this.loadTerminated(this.filterParams);

      // setTimeout(() => {
      //   this.overlay = false;
      // }, 3000);
    },
    isUrgent(val){
      let tagList = ['urgent'];
      return tagList.includes(val);
    },
    isSecret(val){
      let tagList = ['secret'];
      return tagList.includes(val);
    },
    updateCheckAll() {
      if (
        this.selectedItems.length == this.memoIds.length &&
        this.selectedItems.length > 0
      ) {
        this.setSelectAllTerminated(true);
      } else {
        this.setSelectAllTerminated(false);
      }
    },
    cutJudul(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    cutIsi(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    showHideCommentDialog(val) {
      this.showCommentDialog = val;
    },
    commentFilled(val) {
      if (!val) return;
      this.comment = val;
      // this.terminateSelected();
      // console.log("do revoke");
    },
    revokeSelected() {
      if (!this.selectedItems) return;
      if (this.selectedItems.length > 1) {
        return this.$dialog.error({
          text: this.$t("msgbox.cannot-revoke-more-than-one-draft-at-a-time"),
          title: this.$t("msgbox.revoke-sent-memo"),
          persistent: true,
        });
      }
      if (!this.comment) {
        this.submitOptions = {
          btnText: "button.revoke",
          btnIcon: this.iconRevoke,
          commentDesc: this.$t("text.please-fill-revoke-comment-or-reason"),
        };
        return (this.showCommentDialog = true);
      }
    },
  },
};
</script>