<template>
  <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
    <v-toolbar-title>
      {{ heading }}
    </v-toolbar-title>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!enableAction"
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="terminatedReload"
        >
          <v-icon>{{ iconReload }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reload") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="enableAction && selectedItems.length === 1"
          class="ml-sm-0 ml-2"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="purple lighten-2 ml-3"
          @click="revokeSelected"
        >
          <v-icon>{{ iconRevoke }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.revoke-selected-memo") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="enableAction"
          class="ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="purple lighten-2 ml-3"
          @click="terminatedReload"
        >
          <v-icon>{{ iconArchive }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.move-to-archive") }}</span>
    </v-tooltip>

    <v-menu
      v-if="$vuetify.breakpoint.smAndDown && !enableAction"
      v-model="showSearch"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          icon
          color="blue lighten-2"
          class="d-flex d-sm-none"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ iconSearch }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            :label="$t('label.title-or-sender-name')"
            outlined
            v-model="searchQuery"
            dense
            clearable
            hide-details="true"
          ></v-text-field>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="showSearch = false">{{
            $t("button.close")
          }}</v-btn>
          <v-btn color="primary" text @click="applyQuery">{{
            $t("button.search")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown && !enableAction">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-on="on"
          v-bind="attrs"
          v-model="searchQuery"
          :label="$t('label.search')"
          :placeholder="$t('label.memo-title-or-sender')"
          outlined
          dense
          clearable
          hide-details="true"
          class="ml-2 search-input"
          :class="{ 'min-w-300px': searchQuery }"
        ></v-text-field>
      </template>
      <span>{{ $t("tooltip.type-title-or-sender-name-then-enter") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  mdiReload,
  mdiDotsVertical,
  mdiEmailSearchOutline,
  mdiFilterMenuOutline,
  mdiArchiveArrowDownOutline,
  mdiBackupRestore,
} from "@mdi/js";
export default {
  name: "header-left",
  components: {},
  props: {
    heading: {
      type: String,
      default: "",
    },
    enableAction: {
      type: Boolean,
    },
    selectedItems:{
      type: [Object, Array]
    }
  },
  data: () => ({
    iconReload: mdiReload,
    iconMenu: mdiDotsVertical,
    iconSearch: mdiEmailSearchOutline,
    iconFilter: mdiFilterMenuOutline,
    iconRevoke: mdiBackupRestore,
    iconArchive: mdiArchiveArrowDownOutline,
    showSearch: false,
    searchQuery: "",
  }),
  computed: {
    ...mapState("sent", ["filterParams"]),
    ...mapState("user", ["currentUser"]),
    isPjpDir(){
      if (!this.currentUser) return false;
      return ['PJP','PJP_KANPUS','DIREKTUR'].includes(this.currentUser.level_aplikasi_elemen);
    },
  },
  watch: {
    searchQuery(val) {
      if (val && val.length < 3) return;
      let params = this.filterParams;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        params.filter = val ? val : '';
        this.loadTerminated(params);
      }, 1000);
    }
  },
  methods: {
    ...mapActions(["setMessageBox"]),
    ...mapActions("terminated", ["loadTerminated", "selectAllTerminated"]),
    terminatedReload() {
      this.$emit("terminatedReload");
    },
    applyQuery() {
      this.showSearch = false;
      if (!this.searchQuery || this.searchQuery == "") return;
      let params = this.filterParams;
      params.filter = this.searchQuery;
      this.loadTerminated(params);
    },
    revokeSelected(){
      this.$emit("revokeSelected");
    },
    menuAction(slug) {
      if (slug == "reset_filter") {
        this.searchQuery = "";
        // this.setAppMessageBox({
        //   show: true,
        //   type: "error",
        //   title: "error.error-title",
        //   message: "error.network-error",
        // });
      } else {
        this.setMessageBox({
          snackbar: true,
          show: true,
          type: "warning",
         title: "error.warning-title",
         message: "error.this-menu-is-under-construction"
        });
      }
    },
  },
};
</script>